<template>
  <div class="mt-3" v-if="isPermission" id="deliveryProviderContainer">
    <CRow>
      <CCol md="12" class="text-center" id="deliveryProviderHeaderCol">
        <h2 class="font-weight-normal text-dark" id="deliveryProviderTitle">
          {{ $t('deliveryProviders') }}
        </h2>
        <h6 class="text-description" id="deliveryProviderSubtitle">
          {{ $t('deliveryProviderSetting') }}
        </h6>
      </CCol>
    </CRow>
    <hr />
    <CDataTable :items="updateItems()" :fields="fields" hover border striped responsive style="font-weight: normal;">
      <template v-for="(item, index) in updateItems()" #logo="{item}">
        <td>
          <div class="text-center font-weight-normal">
            <img style="width:40%" :src="item.logo">
          </div>
        </td>
      </template>
      <template #no-items-view>
        <div class="text-center mt-5 font-weight-normal" id="deliveryProviderNoItemsView">
          <h4 style="color: #ced2d8;" id="deliveryProviderNoItemsText">
            {{ $t('noItem') }}
          </h4>
        </div>
      </template>
    </CDataTable>
    <CRow id="deliveryProviderModalRow">
      <CModal
        id="deliveryProviderModal"
        color="success"
        :show.sync="popupModal"
        :title="$t('adddeliveryProviders')"
        centered
      >
        <template #footer>
          <div class="mx-auto" id="deliveryProviderFooterContainer">
            <CCol col="12" id="deliveryProviderFooterCol">
              <CButton
                id="deliveryProviderSaveButton"
                color="success"
                v-if="loadingButton === true"
                v-on:click="savedata"
              >
                {{ $t('save') }}
              </CButton>
              <CButto
                id="deliveryProviderDisabledSaveButton"
                block
                color="success"
                v-else-if="loadingButton === false"
                disabled
              >
                <CSpinner id="deliveryProviderSpinner" color="white" size="sm" />{{ $t('save') }}
              </CButto>
            </CCol>
          </div>
          <div class="mx-auto" id="deliveryProviderCancelButtonContainer">
            <CButton id="deliveryProviderCancelButton" color="light" @click="popupModal = false">{{
              $t('cancel')
            }}</CButton>
          </div>
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else id="deliveryProviderAccessDataContainer">
    <access-data></access-data>
  </div>
</template>

<script>
import local from '@/services/local'
import { mapGetters } from 'vuex'
import moment from 'moment'
import permis from '@/util/permission'

export default {
  data() {
    return {
      loadingButton: true,
      loading: '',
      popupModal: false,
      data: [],
    }
  },
  created() {
    this.getdeliveryprovider()
  },
  mounted() {
    this.$store.dispatch('getUser')
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      return permis.findPermissionRead('setting', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    items() {
      let data = this.data
      let detail = []
      for (let i = 0; i < data.length; i++) {
        detail.push({
          name: data[i].name,
          lastUpdated: moment(String(data[i].updated_at)).format(
            'DD MMMM YYYY'
          ),
        })
      }

      return detail
    },
    fields() {
      return [
        { key: 'logo', label: this.$i18n.t(''), _classes: 'font-weight-normal', _style:"width:10%" },
        { key: 'name', label: this.$i18n.t('deliveryProviders'), _classes:'font-weight-normal' },
        { key: 'lastUpdated', label: this.$i18n.t('lastUpdate'), _classes:'font-weight-normal' },
      ]
    },
  },
  methods: {
    updateItems() {
      const logoMap = {
        GrabFood: "/img/delivery/grabfood.png",
        "LINE MAN": "/img/delivery/LINEMAN.png",
        "foodpanda": "/img/delivery/foodpanda.png",
        "GET": "/img/delivery/GET@2x.png",
        "NOW": "/img/delivery/now@2x.png",
        "LALAMOVE": "/img/delivery/LALAMOVE@2x.png",
        "SKOOTAR": "/img/delivery/SKOOTAR@2x.png",
        "honesbee": "/img/delivery/HONESBEE@2x.png",
        "honestbee": "/img/delivery/HONESBEE@2x.png",
        "Thailand Post": "/img/delivery/icon-Thaipost@x2.png",
        "Kerry": "/img/delivery/Kerry@x2.png",
        "DHL": "/img/delivery/DHL@x2.png",
        "7-11 Delivery": "/img/delivery/7-11-delivery@x2.png",
        "Ninja Van": "/img/delivery/Ninjavan@x2.png",
        "J&T": "/img/delivery/J&T@x2.png",
        "Flash Express": "/img/delivery/flash-express@x2.png",
        "Flash Delivery": "/img/delivery/Flash-delivery@x2.png",
        "ShopeeFood": "/img/delivery/Shopee-food.png",
        "Robinhood": "/img/delivery/Robinhood.png",
        "true food": "/img/delivery/truefood.png"
      };

      let data = this.data;
      let detail = [];
      for (let i = 0; i < data.length; i++) {
        detail.push({
          logo: logoMap[data[i].name] || "/img/logo/default.png", // ใช้โลโก้เริ่มต้นถ้าไม่พบ
          name: data[i].name,
          lastUpdated: moment(String(data[i].updated_at)).format('DD/MM/YYYY'),
        });
      }
      console.log(detail)
      return detail;
    },
    getdeliveryprovider() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = {
        shopObjectId: shopObjectId,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      local({
        url: '/api/v1.0/' + uid + '/Shop/deliveryprovider/data',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.data = res.data.data
        this.loadingButton = true
      })
    },
    savedata() {},
  },
  watch: {
  '$i18n.locale': function(newLocale, oldLocale) {
    this.updateItems()
  },
},
}
</script>

<style></style>
